module.exports = {
  home: `Početna`,
  about: `O nama`,
  contact: `Kontakt`,
  products: `Proizvodi`,
  sace: `Saće`,
  saceDigitalServices: `Saće - Digitalni servisi iz Oblaka`,
  saceDigitalServicesUppercased: `SAĆE - DIGITALNI SERVISI IZ OBLAKA`,
  eArchive: `eArhiva`,
  eArchiveDigitalLocker: `eArhiva - Vaš Digitalni Ormar`,
  eArchiveDigitalLockerUppercased: `eArhiva - VAŠ DIGITALNI ORMAR`,
  services: `Usluge`,
  centralizedDigitalCommunication: `Centralizirana digitalna komunikacija`,
  businessProcessAutomatization: `Automatizacija poslovnih procesa`,
  centralizedDigitalCommunicationUppercased: `CENTRALIZIRANA DIGITALNA KOMUNIKACIJA`,
  businessProcessAutomatizationUppercased: `AUTOMATIZACIJA POSLOVNIH PROCESA`,
  remoteWork: `Rad od kuće / Rad na daljinu`,
  ictSystemSolutionDesign: `Projektiranje složenih ICT sustava i rješenja`,
  remoteWorkUppercased: `RAD OD KUĆE / RAD NA DALJINU`,
  ictSystemSolutionDesignUppercased: `PROJEKTIRANJE SLOŽENIH ICT SUSTAVA I RJEŠENJA`,
  demo: `Demo`,
  loginAsMerchant: `Prijavite se kao trgovac`,
  loginAsBank: `Prijavite se kao banka`,
  loginAsAccountant: `Prijavite se kao računovođa`,
  loginAsMerchantAddress: `http://www.sace.hr`,
  loginAsBankAddress: `http://www.sace.hr`,
  loginAsAccountantAddress: `http://www.sace.hr`,
  blog: `Blog`,
  digitalLockerBlog: `“Možemo li Digitalni Ormar koristiti u bankarstvu?”`,
  complaintManagementBlog: `“Upravljanje reklamacijama korisnika”`,
  lunartech: `LUNARTECH`,
  saceText1: `Sigurna Automatizirana Centralizirana E-radna okolina – skup digitalnih proizvoda i online servisa namijenjenih poslovanju mikro, malih i srednjih tvrtki, koje tržištu nudimo putem mjesečnog najma, u vidu gotovih „off the shelf“ proizvoda ili putem implementacijskih projekata i prilagodbe zahtjevima korisnika.`,
  saceText2: `Trenutno aktivno radimo na završetku našeg prvog vlastitog projekta „Razvoj i implementacija Digitalne Arhive u Oblaku“.  Rezultati projekta su usluge digitalnog arhiviranja poslovne dokumentacije, obogaćene digitalizacijom poslovnih procesa, mogućnošću rada od kuće i jednostavnim povezivanjima sa ostalim sudionicima u procesu.`,
  eArchiveText1: `eArhiva je temeljna usluga „SAĆE“ digitalnih servisa.`,
  eArchiveText2: `eArhiva je stalno dostupna usluga on-line arhiviranja digitalnih dokumenata  - 24 sata dnevno, 7 dana u tjednu, obogaćena „end-to-end” automatizacijom poslovnih procesa i elektroničkim upravljanjem dokumentacijom u realnom vremenu (EDMS).`,
  eArchiveText3: `eArhiva je cloud usluga digitalnog arhiviranja dokumenata, namijenjena mikro, malim i srednjim poslovnim korisnicima različitog profila (eArhiva).`,
  eArchiveText4: `Rješenje je autonomno, modularno, korisnički orijentirano, pripremljeno za povezivanje sa vanjskim sustavima gdje je potrebno i može biti sastavni dio platforme za elektroničko upravljanje dokumentima u bilo kojoj korporaciji.`,
  centralizedDigitalCommunicationText: `Temeljna funkcionalnost Saće platforme je centralizirana, jednostavna i brza komunikacija digitalnim kanalima, ostvarena između korisnika platforme te između korisnika platforme i njihovih klijenata,  digitalizacijom poslovnih i komunikacijskih procesa.`,
  businessProcessAutomatizationText1: `Saće platforme pruža Korisnicima usluge centralizirane digitalne komunikacije obogačene automatizacijom i digitalizacijom procesa elektroničkog arhiviranja digitalnih dokumenata, praćenja poslovnih procesa, te upravljanja marketinškim i prodajnim online procesima. `,
  businessProcessAutomatizationText2: `Rješenje je modularno, korisnički orijentirano, pripremljeno za povezivanje sa vanjskim (E)DMS sustavima ako je potrebno i može biti sastavni dio platforme za elektroničko upravljanje dokumentima u bilo kojoj korporaciji.`,
  remoteWorkText: `Podržavati rad na daljinu, bilo od kuće ili bilo gdje drugdje, bez ikakvih dodatnih zahtjeva prema potrebnoj opremi osim potrebe spajanja na internetsku mrežu, glavni je cilj suvremenih tvrtki u njihovim naporima ka rezanju troškova, povećanju efikasnosti rada, povećanju prodaje i proširenju broja klijenata, uz očuvanje okoliša i brigu o prirodi.`,
  ictSystemSolutionDesignText1: `Lunartech d.o.o. pruža i usluge dizajna, razvoja i implementacija složenih računalnih i ICT sustava i rješenja, kao i usluge savjetovanja po tim istim pitanjima.`,
  ictSystemSolutionDesignText2: `Radnici Lunartech d.o.o. su specijalisti za razvoj i implementaciju složenih računarskih sustava i rješenja namijenjenih realizaciji suvremenih usluga mobilnih i internetskih digitalnih plaćanja, usluga Digitalne transformacije poslovanja te usluga elektroničkog arhiviranja.`,
  ictSystemSolutionDesignText3: `Saće platforma je i baza na kojoj se razvijaju nove Usluge, pruža korisnička podrška te obučavaju djelatnici i korisnici.`,
  lunartechFooter1: `LUNARTECH d.o.o., Registracijski sud: Trgovački sud u Zagrebu, MBS: 081328777`,
  lunartechFooter2: `Temeljni kapital društva: 20.000,00 kn uplaćen u cijelosti, Direktor društva: Gordana Mrković`,
  lunartechFooter3: `Tel: `,
  lunartechFooter4: ` , OIB: 44384154513, IBAN: HR5323400091111093923, e: `,
  contactUs: `KONTAKTIRAJTE NAS I SAZNAJTE VIŠE`,
  contactUppercased: `KONTAKT`,
  aboutUppercased: `O NAMA`,
  telephoneNumber: `TELEFON`,
  email: `E-MAIL`,
  office: `URED`,
  aboutLunartech1: `Lunartech d.o.o. je tvrtka osnovana sa ciljem širenja primjene digitalnih tehnologija te digitalizacije i internetizacije poslovanja različitog spektra, u tvrtkama iz non-IT poslovnih područja.`,
  aboutLunartech2: `Želja nam je doprinjeti razvoju i boljitku naših korisnika temeljem dijeljenja expertnog znanja i iskustava, sakupljenih tijekom dugogodišnjeg rada u financijskoj, sigurnosnoj i IT industriji u domaćem i u međunarodnom okruženju, u vidu Usluga vanjskog davatelja usluga.`,
  aboutLunartech3: `Specijalizirani smo za pružanje usluga:`,
  aboutLunartech31: `•	Projektiranja, razvoja i implementacije kompleksnih ICT rješenja namijenjenih digitalizaciji poslovanja tvrtke u različitim poslovnim zonama, krojenih prema potrebama i željama korisnika, te`,
  aboutLunartech32: `•	Nadzora i vodjenja IT projekata i projekata digitalizacije u ime korisnika.`,
  aboutLunartech4: `Bavimo se i razvojem vlastitih digitalnih proizvoda i online servisa, koje nudimo na tržištu u vidu gotovih „off the shelf“ proizvoda, putem vlastite web platforme - „Saće“ i na principima dijeljene ekonomije.`,
  homeImage1: `/carousel/laptop_hr_3.jpg`,
  homeImage2: `/carousel/book_hr_3.jpg`,
  homeImage3: `/carousel/sace_hr_3.jpg`,
  homeImage4: `/carousel/pyramid_hr_3.jpg`,
  homeImage5: `/carousel/pamflet_hr_3.jpg`,
  homeImage6: `/carousel/poem_hr_3.jpg`,
  homeImage1Vertical: `/carousel/laptop_hr_3_vertical.jpg`,
  homeImage2Vertical: `/carousel/book_hr_3_vertical.jpg`,
  homeImage3Vertical: `/carousel/sace_hr_3_vertical.jpg`,
  homeImage4Vertical: `/carousel/pyramid_hr_3_vertical.jpg`,
  homeImage5Vertical: `/carousel/pamflet_hr_3_vertical.jpg`,
  homeImage6Vertical: `/carousel/poem_hr_3_vertical.jpg`,
  eTravelBookDigitalAssistant: `ePutnaKnjiga - Vaš Digitalni Putni Asistent`,
  eTravelBookDigitalAssistantUppercased: `ePutnaKnjiga - VAŠ DIGITALNI PUTNI ASISTENT`,
  eTravelBookDigitalAssistantText1: `ePutnaKnjiga ŠTEDI VAŠE VRIJEME I VAŠ NOVAC.`,
  eTravelBookDigitalAssistantText2: `ePutnaKnjiga je INTELIGENTNI Digitalni Servis za arhiviranje i obradu putnih troškova – CLOUD rješenje namijenjeno automatiziranom kreiranju, vođenju, obračunavanju i arhiviranju vaših putnih troškova - sukladno aktualnim zakonskim propisima bez čitanja propisa i bez upotrebe papira, olovke i kalkulatora.`,
  eTravelBookDigitalAssistantText31: `ePutnaKnjiga je SAĆE servis kojem pristupate putem SAĆE platforme – sve što trebate je`,
  eTravelBookDigitalAssistantText32: `, mobitel, tablet, laptop ili stolno računalo.`,
  eTravelBookDigitalAssistantText4: `ePutnaKnjiga je ON - LINE Servis kojem pristupate 24 sata dnevno, 7 dana u tjednu, u bilo koje vrijeme i od bilo kuda – kuće, putovanja, ureda.`,
  eTravelBookDigitalAssistantText5: `ePutnaKnjiga je INTELIGENTNI Servis koji koristite za izradu izvještaja o putovanju sukladno zakonu.`,
  eTravelBookDigitalAssistantText6: `ePutnaKnjiga je AUTOMATIZIRANI Servis koji koristite za mjesečni obračun putnih troškova i dnevnica za sebe i za svoje klijente ili zaposlene.`,
  eTravelBookDigitalAssistantText7: `ePutnaKnjiga je POUZDANI Servis koji ČUVA vaše putne dokumente i izvještaje sukladno zakonskim potrebama.`,
  eTravelBookDigitalAssistantText8: `ePutnaKnjiga je VAŠ stalno dostupni PRETINAC za DIGITALNE PUTNE DOKUMENTE.`,
  eTravelBookBlog: `“ePutnaKnjiga – ZAŠTO je ePutnaKnjiga bolja od klasične?”`,
  saceUppercased: `SAĆE`,

  homePath: "/",
  nextHomePath: "/en",
  aboutPath: "/about",
  contactPath: "/contact",
  saceParagraphId: "Sace",
  eArchiveParagraphId: "eArhiva",
  eTravelBookParagraphId: "ePutnaKnjiga",
  cdcParagraphId: "CentraliziranaDigitalnaKomunikacija",
  bpaParagraphId: "AutomatizacijaPoslovnihProcesa",
  remoteWorkParagraphId: "RadOdKuce",
  ictParagraphId: "ProjektiranjeICTSustava",
  nextLanguageFlagImg: "/en_flag.jpg",
  nextLanguageFlagImgAlt: "English"
}
